// --- Element451 App Typography --- //

@import 'libs/styles/variables';
@import 'libs/styles/mixins';

@mixin elm-typography($font-size, $line-height: $font-size, $font-weight: 400) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

.elm-display-2 {
  @include elm-typography(42px, 52px, 300);

  margin-bottom: $elm-font-margin-bottom;
}

.elm-display-1 {
  @include elm-typography(34px, 42px, 300);

  @media ($elm-xsmall) {
    @include elm-typography(28px, 36px, 300);
  }

  margin-bottom: $elm-font-margin-bottom;
}

.elm-headline {
  @include elm-typography(22px, 32px, 300);

  margin-bottom: 0;
}

.elm-title {
  @include elm-typography(18px, 28px, 300);

  margin-bottom: $elm-font-margin-bottom;
}

.elm-body-2 {
  @include elm-typography(16px, 24px, 400);

  margin-bottom: $elm-font-margin-bottom;
}

.elm-body-1 {
  @include elm-typography(14px, 20px, 400);

  margin-bottom: $elm-font-margin-bottom;
}

.elm-caption {
  @include elm-typography(12px, 20px, 400);

  margin-bottom: $elm-font-margin-bottom;
}

.elm-subhead {
  @include elm-typography(12px, 20px, 600);

  margin-bottom: $elm-font-margin-bottom;
}

.elm-error {
  @include elm-typography(13px, 24px, 400);
}

.elm-form-field-label {
  @include elm-typography(12px, 20px, 400);
  @include text-overflow(block);

  color: rgba(0, 0, 0, 0.49); // to be matched with rendered color
  pointer-events: none;
  width: 100%;
  cursor: default;
  margin-bottom: 7px;
}

.elm-bold {
  font-weight: bold;
}

.elm-italic {
  font-style: italic;
}
