// --- Variables --- //

// -- Default Colors -- //
$elm-app451-color-gray-bg: #f5f5f5;
$elm-app451-color-dark-gray: #424242;
$elm-app451-color-normal-gray: #757575;
$elm-app451-color-light-gray: #9e9e9e;
$elm-app451-color-lightest-gray: #eeeeee;
$elm-app451-color-dark-blue-gray: #1e282e;
$elm-app451-color-normal-blue-gray: #263239;
$elm-app451-color-light-blue-gray: #78909d;
$elm-app451-color-lightest-blue-gray: #b0bec6;
$elm-app451-color-dark-card-bg: #131a1e;
$elm-app451-color-blue-gray-bg: #303f46;
$elm-app451-color-green: #4eaf3e;
$elm-app451-color-red: #e53b2d;
$elm-app451-color-blue: #2196f3;
$elm-app451-color-yellow: #ffd829;
$elm-app451-color-dark-yellow: #c5b636;

// -- Components Dynamic Theming Classes -- //

// .elm-dynamic-link-color
// .elm-dynamic-link-color-wrapper a
// .elm-dynamic-wysiwyg-link-color
// .elm-dynamic-wysiwyg-link-color-wrapper a
// .elm-dynamic-fg-primary
// .elm-dynamic-fg-accent
// .elm-dynamic-bg-primary
// .elm-dynamic-bg-accent
// .elm-dynamic-svg-line-primary
// .elm-dynamic-svg-line-primary-wrapper svg
// .elm-dynamic-svg-line-accent
// .elm-dynamic-svg-line-accent-wrapper svg
// .elm-dynamic-svg-fill-primary
// .elm-dynamic-svg-fill-primary-wrapper svg
// .elm-dynamic-svg-fill-accent
// .elm-dynamic-svg-fill-accent-wrapper svg

// --- Typography --- //

$elm-app451-font-family-source-sans-pro: 'Source Sans Pro', sans-serif; // font-weight: 300,300i,400,400i,600,600i,700,700i
$elm-app451-font-family-work-sans: 'Work Sans', sans-serif; // font-weight: 300,400,500,600,700

// --- Container --- //

$elm-app451-container-padding-xs: 16px;
$elm-app451-container-padding-sm: 16px;
$elm-app451-container-padding-md: 24px;
$elm-app451-container-padding-lg: 32px;
$elm-app451-container-full-max-width: 1200px;
$elm-app451-container-semifull-max-width: 1000px;

// --- Layout Breakpoints (Mobile-First) --- //

$elm-app451-layout-breakpoint-xs: 320px;
$elm-app451-layout-breakpoint-sm: 480px;
$elm-app451-layout-breakpoint-md: 768px;
$elm-app451-layout-breakpoint-lg: 1024px;

// @media (min-width: $elm-app451-layout-breakpoint-xs) {}
// @media (min-width: $elm-app451-layout-breakpoint-sm) {}
// @media (min-width: $elm-app451-layout-breakpoint-md) {}
// @media (min-width: $elm-app451-layout-breakpoint-lg) {}
