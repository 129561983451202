@import 'container';
@import 'material2';
@import 'vendor/froala-fix';

@mixin page451-core() {
  @include elm-pg-container;

  .elm-pg-page-wrapper {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .elm-pg-above-overlay {
    position: relative;
    z-index: 2;
  }

  .elm-pg-editable {
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: visible;
    }
  }

  .elm-pg-component-background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  span[style~='color:'] a {
    color: inherit !important;

    &:hover,
    &:focus {
      color: inherit !important;
    }
  }
}
