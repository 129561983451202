@use '@angular/material' as mat;

// Element451 App Palette
@import 'element451-app-palette';

@include mat.core();

// Define a custom typography config that overrides the font-family as well as the `title`, `body-2` and `button` levels.
// We overrides only font family and font-weight in `title`, `body-2` and `button` levels to match font-weight with `Source Sans Pro`.
$element451-app-typography: mat.m2-define-typography-config(
  $font-family:
    '"Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
  $button: mat.m2-define-typography-level(14px, 21px, 600)
);

// Create the theme object (a Sass map containing all of the palettes).
// Light theme is a base.
$element-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $element451-app-primary,
      accent: $element451-app-accent,
      warn: $element451-app-warn
    ),
    typography: $element451-app-typography,
    density: 0
  )
);

// Create the theme object (a Sass map containing all of the palettes).
// Dark theme.
$element-app-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $element451-app-primary,
      accent: $element451-app-accent,
      warn: $element451-app-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component that you are using.
@include mat.all-component-themes($element-app-theme);

.elm-mat-dark-theme {
  @include mat.all-component-colors($element-app-dark-theme);
}
