@import 'libs/styles/variables';

.elm-fg-white {
  color: white;
}

.elm-fg-1 {
  color: $elm-fg-1;
}

.elm-fg-2 {
  color: $elm-fg-2;
}

.elm-fg-3 {
  color: $elm-fg-3;
}

.elm-fg-4 {
  color: $elm-fg-4;
}

.elm-fg-5 {
  color: $elm-fg-5;
}

.elm-fg-6 {
  color: $elm-fg-6;
}

.elm-fg-7 {
  color: $elm-fg-7;
}
