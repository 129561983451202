// --- Container --- //

.elm-app451-container-full,
.elm-app451-container-semifull {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @media (min-width: $elm-app451-layout-breakpoint-xs) {
    padding-left: $elm-app451-container-padding-xs;
    padding-right: $elm-app451-container-padding-xs;
  }

  @media (min-width: $elm-app451-layout-breakpoint-sm) {
    padding-left: $elm-app451-container-padding-sm;
    padding-right: $elm-app451-container-padding-sm;
  }

  @media (min-width: $elm-app451-layout-breakpoint-md) {
    padding-left: $elm-app451-container-padding-md;
    padding-right: $elm-app451-container-padding-md;
  }

  @media (min-width: $elm-app451-layout-breakpoint-lg) {
    padding-left: $elm-app451-container-padding-lg;
    padding-right: $elm-app451-container-padding-lg;
  }
}

.elm-app451-container-full {
  max-width: $elm-app451-container-full-max-width;
}

.elm-app451-container-semifull {
  max-width: $elm-app451-container-semifull-max-width;
}
