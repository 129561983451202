// --- Background Colors --- //

@use '@angular/material' as mat;
@import 'libs/styles/variables';
@import 'background-colors-slim';

// Background Primary

.elm-bg-primary {
  background-color: mat.m2-get-color-from-palette($element451-app-primary);
}

.elm-bg-primary-50 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 50);
}

.elm-bg-primary-100 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 100);
}

.elm-bg-primary-200 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 200);
}

.elm-bg-primary-300 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 300);
}

.elm-bg-primary-400 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 400);
}

.elm-bg-primary-500 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 500);
}

.elm-bg-primary-600 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 600);
}

.elm-bg-primary-700 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 700);
}

.elm-bg-primary-800 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 800);
}

.elm-bg-primary-900 {
  background-color: mat.m2-get-color-from-palette($element451-app-primary, 900);
}

.elm-bg-primary-A100 {
  background-color: mat.m2-get-color-from-palette(
    $element451-app-primary,
    A100
  );
}

.elm-bg-primary-A200 {
  background-color: mat.m2-get-color-from-palette(
    $element451-app-primary,
    A200
  );
}

.elm-bg-primary-A400 {
  background-color: mat.m2-get-color-from-palette(
    $element451-app-primary,
    A400
  );
}

.elm-bg-primary-A700 {
  background-color: mat.m2-get-color-from-palette(
    $element451-app-primary,
    A700
  );
}

// Background Accent

.elm-bg-accent {
  background-color: mat.m2-get-color-from-palette($element451-app-accent);
}

.elm-bg-accent-50 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 50);
}

.elm-bg-accent-100 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 100);
}

.elm-bg-accent-200 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 200);
}

.elm-bg-accent-300 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 300);
}

.elm-bg-accent-400 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 400);
}

.elm-bg-accent-500 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 500);
}

.elm-bg-accent-600 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 600);
}

.elm-bg-accent-700 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 700);
}

.elm-bg-accent-800 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 800);
}

.elm-bg-accent-900 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, 900);
}

.elm-bg-accent-A100 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, A100);
}

.elm-bg-accent-A200 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, A200);
}

.elm-bg-accent-A400 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, A400);
}

.elm-bg-accent-A700 {
  background-color: mat.m2-get-color-from-palette($element451-app-accent, A700);
}

// Background Warn

.elm-bg-warn {
  background-color: mat.m2-get-color-from-palette($element451-app-warn);
}

.elm-bg-warn-50 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 50);
}

.elm-bg-warn-100 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 100);
}

.elm-bg-warn-200 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 200);
}

.elm-bg-warn-300 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 300);
}

.elm-bg-warn-400 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 400);
}

.elm-bg-warn-500 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 500);
}

.elm-bg-warn-600 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 600);
}

.elm-bg-warn-700 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 700);
}

.elm-bg-warn-800 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 800);
}

.elm-bg-warn-900 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, 900);
}

.elm-bg-warn-A100 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, A100);
}

.elm-bg-warn-A200 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, A200);
}

.elm-bg-warn-A400 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, A400);
}

.elm-bg-warn-A700 {
  background-color: mat.m2-get-color-from-palette($element451-app-warn, A700);
}

// Background General

.elm-bg-global {
  background-color: $elm-bg-global;
}

.elm-bg-header {
  background-color: $elm-bg-header;
}

.elm-bg-hover {
  background-color: $elm-bg-hover;
}

.elm-bg-selected {
  background-color: $elm-bg-selected;
}

.elm-bg-chip-grey {
  background-color: $elm-bg-chip-grey;
}

.elm-bg-chip-green {
  background-color: $elm-bg-chip-green;
}

.elm-bg-chip-yellow {
  background-color: $elm-bg-chip-yellow;
}

.elm-bg-chip-red {
  background-color: $elm-bg-chip-red;
}

.elm-bg-chip-blue {
  background-color: $elm-bg-chip-blue;
}

.elm-bg-main-nav {
  background-color: $elm-bg-main-nav;
}

.elm-main-nav-container {
  background-color: $elm-bg-main-nav-container;
}

.elm-nav-container-hover {
  background-color: $elm-bg-main-nav-container-hover;
}

.elm-nav-container-selected {
  background-color: $elm-bg-main-nav-container-selected;
}
