@import 'libs/styles/variables';

.mat-mdc-tab-link,
.mat-tab-label {
  min-width: auto !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid $elm-fg-3;
}
