// --- Margins --- //

.elm-app451-margin-xs {
  margin-bottom: 0.5rem !important;
}

.elm-app451-margin-s {
  margin-bottom: 1rem !important;
}

.elm-app451-margin-m {
  margin-bottom: 1.5rem !important;
}

.elm-app451-margin-l {
  margin-bottom: 2rem !important;
}

.elm-app451-margin-xl {
  margin-bottom: 4rem !important;
}
