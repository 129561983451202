@import 'libs/styles/variables';

@mixin scrollbars() {
  overflow-y: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
    width: $elm-spacing-2xs;
    height: $elm-spacing-2xs;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $elm-spacing-2xs;
    background-color: $elm-fg-6;
  }
}
