// --- Typography --- //

@import 'libs/styles/variables';

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: $elm-link-color-default;
  line-height: inherit;

  &:not([class*='mat-']):not([class*='mdc-']) {
    text-decoration: $elm-link-decoration-default;

    &:hover,
    &:focus {
      text-decoration: $elm-link-decoration-hover;
    }
  }

  &:hover,
  &:focus {
    color: $elm-link-color-hover;
    outline: 0;
  }

  &:active {
    text-decoration: none;
  }

  &.tel {
    color: inherit !important;
  }

  &.disabled {
    color: $elm-fg-5;
    pointer-events: none;
    cursor: not-allowed;
  }

  & img {
    border: none;
  }
}
