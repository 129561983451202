// --- Button Base --- //

%elm-app451-button-base {
  background-image: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  zoom: 1;

  &:hover,
  &:focus {
    text-decoration: none !important;
  }

  &[disabled] {
    box-shadow: none;
    opacity: 0.4;
    pointer-events: none;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  body &.default-margin {
    margin: 0 3px 6px 0;
  }

  span + elm-icon {
    margin-left: 8px;
  }
}

@keyframes elm-app451-btn-ct-icon-animation {
  0% {
    transform: translate(-50%, -50%);
  }

  20% {
    transform: translate(-38px, -50%);
  }

  40% {
    transform: translate(-38px, -38px);
  }

  60% {
    transform: translate(38px, -38px);
  }

  80% {
    transform: translate(38px, -50%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes elm-app451-btn-ct-text-animation {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(-150%, 0);
  }

  40% {
    transform: translate(-150%, -150%);
  }

  60% {
    transform: translate(150%, -150%);
  }

  80% {
    transform: translate(150%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes elm-app451-btn-ct-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
