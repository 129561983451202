// --- Background Colors --- //

.elm-app451-bg-white {
  background-color: white;
}

.elm-app451-bg-black {
  background-color: black;
}

.elm-app451-bg-gray-bg {
  background-color: $elm-app451-color-gray-bg;
}

.elm-app451-bg-dark-gray {
  background-color: $elm-app451-color-dark-gray;
}

.elm-app451-bg-normal-gray {
  background-color: $elm-app451-color-normal-gray;
}

.elm-app451-bg-light-gray {
  background-color: $elm-app451-color-light-gray;
}

.elm-app451-bg-lightest-gray {
  background-color: $elm-app451-color-lightest-gray;
}

.elm-app451-bg-dark-blue-gray {
  background-color: $elm-app451-color-dark-blue-gray;
}

.elm-app451-bg-normal-blue-gray {
  background-color: $elm-app451-color-normal-blue-gray;
}

.elm-app451-bg-light-blue-gray {
  background-color: $elm-app451-color-light-blue-gray;
}

.elm-app451-bg-lightest-blue-gray {
  background-color: $elm-app451-color-lightest-blue-gray;
}

.elm-app451-bg-dark-card-bg {
  background-color: $elm-app451-color-dark-card-bg;
}

.elm-app451-bg-blue-gray-bg {
  background-color: $elm-app451-color-blue-gray-bg;
}

.elm-app451-bg-green {
  background-color: $elm-app451-color-green;
}

.elm-app451-bg-red {
  background-color: $elm-app451-color-red;
}

.elm-app451-bg-blue {
  background-color: $elm-app451-color-blue;
}

.elm-app451-bg-yellow {
  background-color: $elm-app451-color-yellow;
}
