@use 'sass:math';
@import 'variables';

@mixin elm-pg-container {
  .elm-pg-container {
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    max-width: $container-max-width;

    padding-left: 24px;
    padding-right: 24px;

    .elm-pg-row {
      @media (min-width: $layout-breakpoint-xs) {
        margin-left: -(math.div($grid-gutter-xs, 2));
        margin-right: -(math.div($grid-gutter-xs, 2));
      }

      @media (min-width: $layout-breakpoint-md) {
        margin-left: -(math.div($grid-gutter-md, 2));
        margin-right: -(math.div($grid-gutter-md, 2));
      }

      @media (min-width: $layout-breakpoint-lg) {
        margin-left: -(math.div($grid-gutter-lg, 2));
        margin-right: -(math.div($grid-gutter-lg, 2));
      }
    }

    .elm-pg-col {
      @media (min-width: $layout-breakpoint-xs) {
        padding: 0 (math.div($grid-gutter-xs, 2));
      }

      @media (min-width: $layout-breakpoint-md) {
        padding: 0 (math.div($grid-gutter-md, 2));
      }

      @media (min-width: $layout-breakpoint-lg) {
        padding: 0 (math.div($grid-gutter-lg, 2));
      }
    }
  }
}
