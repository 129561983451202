@import 'libs/page451-lib/src/lib/core/styles/variables';
@import 'common/shape';

// --- Button Base --- //

%elm-button-base {
  background-image: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  zoom: 1;

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px darken($link-color-hover, 10%);
  }

  &[disabled] {
    box-shadow: none;
    opacity: 0.4;
    pointer-events: none;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}
