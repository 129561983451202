/* 
* With material v15 upgrade, it has been changed how material form field renders hints.
* We've two options: to keep default behavior which is to render hint in a single line
* OR
* to change mat-form-field hint behavior through `subscriptSizing` input by setting it to `dynamic`.
* When set to dynamic, it calculates needed space, but it also removes element that used to be there
* and had height of 22px which was allocated for single line hint.
* With adding directive, that element is gone and our layouts are broken so all fields are sticked to each other.
* To fix this, we're checking if field has dynamic sizing and if it does, we're adding margin bottom of 22px to keep old behavior.
*/
.mat-mdc-form-field:has(.mat-mdc-form-field-subscript-dynamic-size) {
  margin-bottom: 22px;
}

.mat-mdc-form-field-infix {
  width: auto;
}
