@import 'libs/styles/variables';

.elm-bg-white {
  background-color: white;
}

.elm-bg-1 {
  background-color: $elm-bg-1;
}

.elm-bg-2 {
  background-color: $elm-bg-2;
}

.elm-bg-3 {
  background-color: $elm-bg-3;
}

.elm-bg-4 {
  background-color: $elm-bg-4;
}

.elm-bg-5 {
  background-color: $elm-bg-5;
}

.elm-bg-6 {
  background-color: $elm-bg-6;
}

.elm-bg-7 {
  background-color: $elm-bg-7;
}

.elm-bg-8 {
  background-color: $elm-bg-8;
}

.elm-bg-9 {
  background-color: $elm-bg-9;
}

.elm-bg-10 {
  background-color: $elm-bg-10;
}

.elm-bg-11 {
  background-color: $elm-bg-11;
}
