@import 'libs/styles/variables';

.mat-button-toggle,
.mat-button-toggle-appearance-standard {
  background-color: transparent;
  color: #aaaaaa;
}
.mat-button-toggle-checked {
  background-color: white;
  color: $elm-primary-color;
  &.mat-button-toggle-appearance-standard {
    color: $elm-primary-color;
  }

  .mat-button-toggle-focus-overlay {
    border-bottom-width: 48px !important;
  }
}
