// --- Element451 Component Library Variables --- //

@use '@angular/material' as mat;

// Element451 App Palette
@import 'material2/element451-app-palette';

// Global Typography
$elm-font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
$elm-font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$elm-font-margin-bottom: 16px;

// Media Queries - Desktop-first approach
// .example-media-query {
//     background-color: red; // large (default)
//     @media ($elm-medium) {
//         background-color: blue; // medium
//     }
//     @media ($elm-small) {
//         background-color: green; // small
//     }
// }

// Element Media Queries
$elm-xsmall: 'max-width: 719px'; // @media ($elm-xsmall) { }
$elm-small: 'max-width: 839px'; // @media ($elm-small) { }
$elm-medium: 'max-width: 1279px'; // @media ($elm-medium) { }
$elm-large: 'max-width: 1439px'; // @media ($elm-large) { }
$elm-xlarge: 'max-width: 5000px'; // @media ($elm-xlarge) { }

// Element Angular Flex Layout - Aliases and MediaQueries
// 'xs': '(min-width: 0px) and (max-width: 719px)'
// 'gt-xs': '(min-width: 720px)'
// 'lt-sm': '(max-width: 719px)'
// 'sm': '(min-width: 720px) and (max-width: 839px)'
// 'gt-sm': '(min-width: 840px)'
// 'lt-md': '(max-width: 839px)'
// 'md': '(min-width: 840px) and (max-width: 1279px)'
// 'gt-md': '(min-width: 1280px)'
// 'lt-lg': '(max-width: 1279px)'
// 'lg': '(min-width: 1280px) and (max-width: 1439px)'
// 'gt-lg': '(min-width: 1440px)'
// 'lt-xl': '(max-width: 1439px)'
// 'xl': '(min-width: 1440px) and (max-width: 5000px)'

// z-index Master List
// since z-indexes are not defined in material 2, as a temp solution we'll used vars from material 1
$elm-z-index-toast: 105 !default;
$elm-z-index-tooltip: 100 !default;
$elm-z-index-drawer: 100 !default; // defined in material 2
$elm-z-index-calendar-pane: 100 !default;
$elm-z-index-select: 90 !default;
$elm-z-index-dialog: 80 !default;
$elm-z-index-menu: 75 !default; // Dialog anad overlay should have higher z-index than navigation
$elm-z-index-bottom-sheet: 70 !default;
$elm-z-index-scroll-mask: 50 !default;
$elm-z-index-scroll-mask-bar: 65 !default;
$elm-z-index-sidenav: 60 !default;
$elm-z-index-backdrop: 50 !default;
$elm-z-index-fab: 20 !default; // defined in material 2
$elm-z-index-table-action: 10 !default; // element custom
$elm-z-index-progress-circular: 2 !default; // Used to fix animation bug in Chrome (deprecated probably)

$elm-primary-color: mat.m2-get-color-from-palette($element451-app-primary);
$elm-accent-color: mat.m2-get-color-from-palette($element451-app-accent);
$elm-warn-color: mat.m2-get-color-from-palette($element451-app-warn);

// Global Link
$elm-link-color-default: mat.m2-get-color-from-palette($element451-app-primary);
$elm-link-color-hover: $elm-link-color-default;
$elm-link-decoration-default: none;
$elm-link-decoration-hover: underline;

// Foreground Colors
$elm-fg-1: rgba(black, 0.04);
$elm-fg-2: rgba(black, 0.06);
$elm-fg-3: rgba(black, 0.12);
$elm-fg-4: rgba(black, 0.26); // slider-off
$elm-fg-5: rgba(
  black,
  0.38
); // disabled, disabled-button, disabled-text, slider-off-active
$elm-fg-6: rgba(black, 0.54); // secondary-text, icon, icons
$elm-fg-7: rgba(black, 0.87); // text, slider-min

// Background Colors
$elm-bg-1: mat.m2-get-color-from-palette(mat.$m2-grey-palette, A100); // #ffffff
$elm-bg-2: mat.m2-get-color-from-palette(
  mat.$m2-grey-palette,
  50
); // #fafafa (background)
$elm-bg-3: mat.m2-get-color-from-palette(
  mat.$m2-grey-palette,
  100
); // #f5f5f5 (app-bar)
$elm-bg-4: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 200); // #eeeeee
$elm-bg-5: mat.m2-get-color-from-palette(
  mat.$m2-grey-palette,
  300
); // #e0e0e0 (status-bar, selected-button, unselected-chip)
$elm-bg-6: mat.m2-get-color-from-palette(
  mat.$m2-grey-palette,
  400
); // #bdbdbd (selected-disabled-button)
$elm-bg-7: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 500); // #9e9e9e
$elm-bg-8: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 600); // #757575
$elm-bg-9: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 700); // #616161
$elm-bg-10: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 800); // #424242
$elm-bg-11: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 900); // #212121
$elm-bg-global: $elm-bg-3; // vertical-sidebar, content and navigation components
$elm-bg-header: #ffffff;
$elm-bg-hover: mat.m2-get-color-from-palette($element451-app-primary, 50, 0.2);
$elm-bg-selected: mat.m2-get-color-from-palette($element451-app-primary, 50);
$elm-bg-chip-grey: $elm-bg-7;
$elm-bg-chip-green: #72b32c; // old color
$elm-bg-chip-yellow: #c5b636; // old color
$elm-bg-chip-red: mat.m2-get-color-from-palette(
  mat.$m2-red-palette,
  500
); // #f44336
$elm-bg-chip-blue: mat.m2-get-color-from-palette(
  mat.$m2-blue-palette,
  500
); // #2196f3
$elm-bg-main-nav: $elm-bg-global;
$elm-bg-main-nav-container: $elm-bg-global;
$elm-bg-main-nav-container-hover: #ededed; // this is HEX code of $elm-bg-7 color darkened 3% in css
$elm-bg-main-nav-container-selected: #f64800; // this is HEX code of $elm-brand color darkened 3% in css

// used to highlight tables, cells, etc.
$elm-color-error-indicator: #fff2f1;
$elm-color-error-indicator-interaction: #fde4e2;

// Border Colors
$elm-border-hover: mat.m2-get-color-from-palette($element451-app-primary, 100);
$elm-border-selected: mat.m2-get-color-from-palette(
  $element451-app-primary,
  500
);

// Dropzone Colors
$elm-dropzone-activated-border: #1e88e5;
$elm-dropzone-activated-bg: rgba(227, 242, 253, 0.5);
$elm-dropzone-entered-border: #689f38;
$elm-dropzone-entered-bg: #f1f8e9;

// Cards Colors
$elm-color-cards-1: #afc7da;
$elm-color-cards-2: #2daac1;
$elm-color-cards-3: #8ad3c4;
$elm-color-cards-4: #1e4885;
$elm-color-cards-5: #e0c4b2;
$elm-color-cards-6: #e290a0;
$elm-color-cards-7: #f1a173;
$elm-color-cards-8: #ebe39d;
$elm-color-cards-9: #97565b;
$elm-color-cards-10: #cfd0d3;
$elm-color-cards-11: #eacf7d;
$elm-color-cards-12: #86797d;
$elm-color-cards-13: #b2c326;
$elm-color-cards-14: #6188e2;
$elm-color-cards-15: #a748ca;

// Horizontal Rule
$elm-rule-height: 1px;
$elm-color-rule: $elm-fg-3;

// Brand Color
$elm-color-brand: #fe5000;

// Zoom Color
$zoom-color: #2d8cff;

// Icon Color
$elm-color-icon-standard: $elm-fg-4;
$elm-color-icon-action-blue: mat.m2-get-color-from-palette(
  mat.$m2-blue-palette,
  500
); // #2196f3
$elm-color-icon-main-navigation: mat.m2-get-color-from-palette(
  mat.$m2-blue-grey-palette,
  700
); // #455a64
$elm-color-icon-cards: mat.m2-get-color-from-palette(
  mat.$m2-blue-palette,
  300
); // #64b5f6 (icons in cards)
$elm-color-icon-activity-account: mat.m2-get-color-from-palette(
  mat.$m2-purple-palette,
  300
); // #ba68c8
$elm-color-icon-activity-application: mat.m2-get-color-from-palette(
  mat.$m2-teal-palette,
  300
); // #4db6ac
$elm-color-icon-activity-email: mat.m2-get-color-from-palette(
  mat.$m2-red-palette,
  300
); // #e57373
$elm-color-icon-activity-info-request: mat.m2-get-color-from-palette(
  mat.$m2-indigo-palette,
  300
); // #7986cb
$elm-color-icon-activity-page: mat.m2-get-color-from-palette(
  mat.$m2-light-blue-palette,
  300
); // #4fc3f7
$elm-color-icon-activity-sms: mat.m2-get-color-from-palette(
  mat.$m2-light-green-palette,
  300
); // #aed581
$elm-color-icon-activity-survey: mat.m2-get-color-from-palette(
  mat.$m2-orange-palette,
  300
); // #ffb74d
$elm-color-icon-activity-form: $elm-color-cards-3;
$elm-color-icon-warn: mat.m2-get-color-from-palette(
  mat.$m2-red-palette,
  500
); // #f44336
$elm-color-icon-success: mat.m2-get-color-from-palette(
  mat.$m2-light-green-palette,
  300
);
$elm-color-icon-zoom: $zoom-color;
$elm-color-icon-action-grey: $elm-fg-6;

// Button Color
$elm-color-button: mat.m2-get-color-from-palette(
  mat.$m2-blue-palette,
  500
); // #607d8b
$elm-color-button-border: $elm-fg-3;

// Spacing
$elm-spacing-3xs: 2px;
$elm-spacing-2xs: 4px;
$elm-spacing-xs: 8px;
$elm-spacing-s: 12px;
$elm-spacing-m: 16px;
$elm-spacing-l: 24px;
$elm-spacing-xl: 32px;
$elm-spacing-2xl: 40px;
$elm-spacing-3xl: 48px;
$elm-spacing-4xl: 56px;
$elm-spacing-5xl: 64px;
$elm-spacing-6xl: 72px;
$elm-spacing-7xl: 80px;
$elm-spacing-8xl: 88px;
$elm-spacing-9xl: 96px;
$elm-spacing-10xl: 104px;
$elm-spacing-11xl: 112px;
$elm-spacing-12xl: 120px;
$elm-spacing-13xl: 128px;

// Size

$elm-size-icon-xs: $elm-spacing-xs;
$elm-size-icon-s: $elm-spacing-s; // header
$elm-size-icon-m: $elm-spacing-m;
$elm-size-icon-l: $elm-spacing-l; // vertical-tab
$elm-size-icon-xl: $elm-spacing-xl;
$elm-size-icon-2xl: $elm-spacing-2xl;
$elm-size-icon-5xl: $elm-spacing-5xl;

$elm-size-icon-standard: $elm-size-icon-l; // standard
$elm-size-icon-main-nav: $elm-spacing-xl;
$elm-size-icon-empty-state: 112px;

$elm-size-chip-large-height: $elm-spacing-xl;
$elm-size-avatar-s: $elm-spacing-s; // avatar-assigned
$elm-size-avatar-m: $elm-spacing-m; // avatar-chip
$elm-size-avatar-xl: $elm-spacing-xl; // avatar-regular
$elm-size-avatar-11xl: $elm-spacing-11xl;
$elm-size-avatar-12xl: $elm-spacing-12xl;
$elm-size-avatar-13xl: $elm-spacing-13xl;
$elm-size-main-nav-icon-container: 56px;
$elm-size-border: 1px;

// Page Layouts
$elm-breadcrumbs-area-padding-lg: $elm-spacing-m $elm-spacing-l $elm-spacing-m
  $elm-spacing-l;
$elm-breadcrumbs-area-padding-md: $elm-spacing-m $elm-spacing-l $elm-spacing-m
  $elm-spacing-l;
$elm-breadcrumbs-area-padding-sm: $elm-spacing-m $elm-spacing-m $elm-spacing-m
  $elm-spacing-m;

$elm-header-area-padding-lg: $elm-spacing-xs $elm-spacing-l $elm-spacing-l
  $elm-spacing-l;
$elm-header-area-padding-md: $elm-spacing-xs $elm-spacing-l $elm-spacing-l
  $elm-spacing-l;
$elm-header-area-padding-sm: $elm-spacing-xs $elm-spacing-m $elm-spacing-m
  $elm-spacing-m;

$elm-content-area-padding-lg: $elm-spacing-l $elm-spacing-l $elm-spacing-l
  $elm-spacing-l;
$elm-content-area-padding-md: $elm-spacing-l $elm-spacing-l $elm-spacing-l
  $elm-spacing-l;
$elm-content-area-padding-sm: $elm-spacing-m $elm-spacing-m $elm-spacing-m
  $elm-spacing-m;

$elm-layout-gutter-xl: $elm-spacing-l;
$elm-layout-gutter-lg: $elm-spacing-l;
$elm-layout-gutter-md: $elm-spacing-l;
$elm-layout-gutter-sm: $elm-spacing-m;
$elm-layout-gutter-xs: $elm-spacing-xs;

// Margins
$elm-margin-bottom-form-element: 8px;
$elm-margin-bottom-content-title-subtitle: 24px;
$elm-margin-bottom-content-section: 32px;

// Shadows
$elm-shadow-behind-box: 0 2px 12px rgba(black, 0.2);
