// --- Foreground Colors --- //

.elm-app451-fg-white {
  color: white !important;
}

.elm-app451-fg-black {
  color: black;
}

.elm-app451-fg-gray-bg {
  color: $elm-app451-color-gray-bg;
}

.elm-app451-fg-dark-gray {
  color: $elm-app451-color-dark-gray !important;
}

.elm-app451-fg-normal-gray {
  color: $elm-app451-color-normal-gray;
}

.elm-app451-fg-light-gray {
  color: $elm-app451-color-light-gray;
}

.elm-app451-fg-lightest-gray {
  color: $elm-app451-color-lightest-gray;
}

.elm-app451-fg-dark-blue-gray {
  color: $elm-app451-color-dark-blue-gray;
}

.elm-app451-fg-normal-blue-gray {
  color: $elm-app451-color-normal-blue-gray;
}

.elm-app451-fg-light-blue-gray {
  color: $elm-app451-color-light-blue-gray;
}

.elm-app451-fg-lightest-blue-gray {
  color: $elm-app451-color-lightest-blue-gray;
}

.elm-app451-fg-dark-card-bg {
  color: $elm-app451-color-dark-card-bg;
}

.elm-app451-fg-blue-gray-bg {
  color: $elm-app451-color-blue-gray-bg;
}

.elm-app451-fg-green {
  color: $elm-app451-color-green;
}

.elm-app451-fg-red {
  color: $elm-app451-color-red;
}

.elm-app451-fg-blue {
  color: $elm-app451-color-blue;
}

.elm-app451-fg-yellow {
  color: $elm-app451-color-yellow;
}
