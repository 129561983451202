// this is applied globally, check this
.lum-display-1,
.lum-display-2,
.lum-display-3,
.lum-display-4,
.lum-display-5,
.lum-body-1,
.lum-body-2,
.lum-body-3 {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal;
  }
}

.fr-view span[style~='color:'] a {
  color: inherit !important;
}
