// --- Helpers --- //

@import 'libs/styles/variables';
@import 'libs/styles/mixins';

// General Resets

.elm-no-float {
  float: none !important;
}

.elm-no-border {
  border: none !important;
}

.elm-no-border-top {
  border-top: none !important;
}

.elm-no-border-right {
  border-right: none !important;
}

.elm-no-border-bottom {
  border-bottom: none !important;
}

.elm-no-border-left {
  border-left: none !important;
}

.elm-no-select {
  user-select: none !important;
  cursor: default !important;
}

// Text Modifications

.elm-text-uppercase {
  text-transform: uppercase !important;
}

.elm-text-lowercase {
  text-transform: lowercase !important;
}

.elm-text-capitalize {
  text-transform: capitalize !important;
}

.elm-text-truncate {
  @include text-overflow;
}

// Text Alignments

.elm-text-left {
  text-align: left !important;
}

.elm-text-right {
  text-align: right !important;
}

.elm-text-center {
  text-align: center !important;
}

.elm-text-justify {
  text-align: justify !important;
}

// Positions

.elm-position-relative {
  position: relative !important;
}

.elm-position-absolute {
  position: absolute !important;
}

.elm-position-static {
  position: static !important;
}

.elm-position-fixed {
  position: fixed !important;
}

.elm-top-0 {
  top: 0 !important;
}

.elm-right-0 {
  right: 0 !important;
}

.elm-bottom-0 {
  bottom: 0 !important;
}

.elm-left-0 {
  left: 0 !important;
}

// Display Types

.elm-none {
  display: none !important;
}

.elm-block {
  display: block !important;
}

.elm-flex {
  display: flex !important;
}

.elm-inline-block {
  display: inline-block !important;
}

.elm-inline {
  display: inline !important;
}

.elm-invisible {
  visibility: hidden !important;
}

// Overflows

.elm-overflow-hidden {
  overflow: hidden !important;
}

.elm-overflow-scroll {
  overflow: scroll !important;
}

.elm-overflow-auto {
  overflow: auto !important;
}

// Floats

.elm-float-left {
  float: left !important;
}

.elm-float-right {
  float: right !important;
}

.elm-center-block {
  @include center-block;
}

.elm-clearfix {
  @include clearfix;
}

// Font weight

.elm-fw-300 {
  font-weight: 300;
}

// Lists

.elm-list-unstyled {
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;

  li {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.elm-list-inline {
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;

  li {
    margin: 0 !important;
    padding: 0 !important;
    display: inline-block !important;
  }
}

// Images

.elm-img-responsive {
  @include img-responsive;
}

.elm-img-full-width {
  width: 100% !important;
  height: auto !important;
}

.elm-img-left {
  float: left !important;
  margin-right: 24px !important;
}

.elm-img-right {
  float: right !important;
  margin-left: 24px !important;
}

.elm-img-cover {
  background-size: cover !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  max-width: 100% !important;
  height: auto !important;

  &.elm-img-cover-center {
    background-position: center center !important;
  }
}

// Forms

.elm-responsive-field {
  width: 100%;
  max-width: 900px;

  // to fix the issue on larger screens where 2 fields are one beside another
  display: block !important;
}

// Misc

.elm-full-width {
  width: 100% !important;
}

.elm-full-height {
  height: 100% !important;
}

.elm-cursor-pointer {
  cursor: pointer !important;
}

// `white-space: nowrap` breaks flexbox layout
// this will fix that flex behaviour and few more similar cases
.elm-flex-min-width-fix {
  min-width: 0;
}

.elm-page {
  display: block;
  width: 100%;
  height: 100%;
}

input[type='number'] {
  &.no-controls {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.elm-custom-scroll {
  @include scrollbars();
}
