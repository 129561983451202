// --- Variables --- //

// -- Client Colors are dynamic -- //

// -- Default Colors -- //

$color-gray-bg: #f5f5f5;
$color-darkest-gray: #212121;
$color-dark-gray: #424242;
$color-normal-gray: #757575;
$color-light-gray: #9e9e9e;
$color-lightest-gray: #eeeeee;
$color-blue: #1084da;
$color-red: #e01b22;

// -- Teasers Colors -- //

$color-light-blue-gray: #b0bec6;
$color-blue-gray: #78909d;
$color-dark-blue-gray: #263239;

// -- Froala Colors -- //

$color-froala-blue: #2196f3;

// --- Global Colors --- //

$body-bg: white;
$text-color: $color-dark-gray;

// --- Default Link Color --- //

$link-color-default: #0f77c7;
$link-color-hover: $link-color-default;
$link-hover-decoration: underline;

// --- Social Network Colors --- //

$color-bg-facebook: #3b5998;
$color-bg-twitter: #000;
$color-bg-youtube: #cd201f;
$color-bg-linkedin: #0077b5;
$color-bg-instagram: radial-gradient(
  circle at 30% 107%,
  #fdf497 0%,
  #fdf497 5%,
  #fd5949 45%,
  #d6249f 60%,
  #285aeb 90%
);
$color-bg-tiktok: #000;

// --- Checkerboard --- //

$checkerboard: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgAQMAAABJtOi3AAAAAXNSR0IArs4c6QAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAAZQTFRFzMzM////040VdgAAABRJREFUCNdjYPgPhEQQRCpjoLJ5AO29P8G2eCQbAAAAAElFTkSuQmCC');

// --- Typography --- //

$font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

// --- Container --- //

$container-padding: 24px;
$container-max-width: 1200px;

// --- Grid --- //

$grid-columns: 12;

$grid-gutter-xs: 8px;
$grid-gutter-md: 16px;
$grid-gutter-lg: 24px;

// --- Layout Breakpoints (Mobile-First) --- //

$layout-breakpoint-xs: 320px;
$layout-breakpoint-md: 600px;
$layout-breakpoint-lg: 960px;

// Examples: Mobile first approach
// @media (min-width: $layout-breakpoint-xs) {}
// @media (min-width: $layout-breakpoint-md) {}
// @media (min-width: $layout-breakpoint-lg) {}

// Examples: Target specific breakpoint only
// @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-md - 1) {}
// @media (min-width: $layout-breakpoint-md) and (max-width: 830px) {} // exception (will be used ONLY for 3 columns)
// @media (min-width: $layout-breakpoint-md) and (max-width: $layout-breakpoint-lg - 1) {}
// @media (min-width: $layout-breakpoint-lg) {}
