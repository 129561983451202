// --- Angular Flex Layout --- //
@use 'sass:math';
@use '@angular/material' as mat;
@import 'libs/styles/variables';

[elmFxLayout] {
  margin: 0 - (math.div($elm-layout-gutter-xl, 2));

  @media ($elm-large) {
    margin: 0 - (math.div($elm-layout-gutter-lg, 2));
  }

  @media ($elm-medium) {
    margin: 0 - (math.div($elm-layout-gutter-md, 2));
  }

  @media ($elm-small) {
    margin: 0 - (math.div($elm-layout-gutter-sm, 2));
  }

  @media ($elm-xsmall) {
    margin: 0 - (math.div($elm-layout-gutter-xs, 2));
  }

  .elm-flex-gutter {
    padding: 0 (math.div($elm-layout-gutter-xl, 2));

    @media ($elm-large) {
      padding: 0 (math.div($elm-layout-gutter-lg, 2));
    }

    @media ($elm-medium) {
      padding: 0 (math.div($elm-layout-gutter-md, 2));
    }

    @media ($elm-small) {
      padding: 0 (math.div($elm-layout-gutter-sm, 2));
    }

    @media ($elm-xsmall) {
      padding: 0 (math.div($elm-layout-gutter-xs, 2));
    }

    & > * {
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      box-sizing: border-box;
    }
  }
}

[elmFxFlexDemo] {
  background-color: mat.m2-get-color-from-palette($element451-app-primary);
  text-align: center;
  overflow: hidden;
  margin: 0;
  padding: 2em 0.5em;
  color: white;
  border-bottom: 1px solid mat.m2-get-color-from-palette($element451-app-accent);
}
