@import 'libs/styles/variables';

.elm-dialog-pane {
  .mat-mdc-dialog-container {
    padding: 0 !important;
    > *:not(.mat-mdc-dialog-title),
    > *:not(.mat-mdc-dialog-content),
    > *:not(.mat-mdc-dialog-actions) {
      display: block;
      width: 100%;
    }
    .mat-mdc-dialog-title,
    .mat-mdc-dialog-content,
    .mat-mdc-dialog-actions {
      background-color: $elm-bg-header;
      padding: 0;
      margin: 0;
    }
    .mat-mdc-dialog-title {
      padding: 0 $elm-spacing-xl $elm-spacing-l;
      border-bottom: 1px solid $elm-color-button-border;
    }
    .mat-mdc-dialog-actions {
      padding: $elm-spacing-xs;
      border-top: 1px solid $elm-color-button-border;
      display: block;
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  width: 60%;
  max-width: 60vw;
  @media ($elm-small) {
    width: 90% !important;
    max-width: 90vw !important;
  }
  @media ($elm-xsmall) {
    width: 94% !important;
    max-width: 94vw !important;
  }

  &.media-manager-dialog-pane {
    width: 90% !important;
    max-width: 90vw !important;
  }
}
