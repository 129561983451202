/**
 * Use this helper class on mat-option to make expandable
 * when you need content to wrap into multiple lines
 * and not to be truncated
 */
.mat-mdc-option.elm-mat-option-expanded {
  overflow: visible;
  white-space: pre-wrap;
  line-height: 1 !important;
  padding: 8px 16px;
  height: auto !important;
  min-height: 48px;
}
