// --- Spacing --- //

@import 'libs/styles/variables';

body .elm-spacing-3xs {
  margin-bottom: $elm-spacing-3xs;
}

body .elm-spacing-2xs {
  margin-bottom: $elm-spacing-2xs;
}

body .elm-spacing-xs {
  margin-bottom: $elm-spacing-xs;
}

body .elm-spacing-s {
  margin-bottom: $elm-spacing-s;
}

body .elm-spacing-m {
  margin-bottom: $elm-spacing-m;
}

body .elm-spacing-l {
  margin-bottom: $elm-spacing-l;
}

body .elm-spacing-xl {
  margin-bottom: $elm-spacing-xl;
}

body .elm-spacing-2xl {
  margin-bottom: $elm-spacing-2xl;
}

body .elm-spacing-3xl {
  margin-bottom: $elm-spacing-3xl;
}

body .elm-spacing-4xl {
  margin-bottom: $elm-spacing-4xl;
}

body .elm-spacing-5xl {
  margin-bottom: $elm-spacing-5xl;
}

body .elm-spacing-6xl {
  margin-bottom: $elm-spacing-6xl;
}

body .elm-spacing-7xl {
  margin-bottom: $elm-spacing-7xl;
}

body .elm-spacing-8xl {
  margin-bottom: $elm-spacing-8xl;
}

body .elm-spacing-9xl {
  margin-bottom: $elm-spacing-9xl;
}

body .elm-spacing-10xl {
  margin-bottom: $elm-spacing-10xl;
}

body .elm-spacing-11xl {
  margin-bottom: $elm-spacing-11xl;
}

body .elm-spacing-12xl {
  margin-bottom: $elm-spacing-12xl;
}

body .elm-spacing-13xl {
  margin-bottom: $elm-spacing-13xl;
}
