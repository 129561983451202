// --- Typography --- //

@import 'mixins/typography';
@import 'button-base';

.elm-app451-display-1 {
  @include elm-app451-display-1;
}

.elm-app451-display-2 {
  @include elm-app451-display-2;
}

.elm-app451-display-3 {
  @include elm-app451-display-3;
}

.elm-app451-display-4 {
  @include elm-app451-display-4;
}

.elm-app451-display-5 {
  @include elm-app451-display-5;
}

.elm-app451-display-tray {
  @include elm-app451-display-tray;
}

.elm-app451-body-1 {
  @include elm-app451-body-1;
}

.elm-app451-body-2 {
  @include elm-app451-body-2;
}

.elm-app451-btn {
  @extend %elm-app451-button-base;
  @include elm-app451-btn;
}

.elm-app451-btn-wide {
  @extend %elm-app451-button-base;
  @include elm-app451-btn-wide;
}

.elm-app451-btn-wide-xl {
  @extend %elm-app451-button-base;
  @include elm-app451-btn-wide-xl;
}

.elm-app451-btn-round {
  @extend %elm-app451-button-base;
  @include elm-app451-btn-round;
}

.elm-app451-btn-dotted {
  @extend %elm-app451-button-base;
  @include elm-app451-btn-dotted;
}

.elm-app451-label {
  @include elm-app451-label;
}

.elm-app451-btn-label-s {
  @include elm-app451-btn-label-s;
}

.elm-app451-btn-label-l {
  @include elm-app451-btn-label-l;
}

.elm-app451-btn-label-xl {
  @include elm-app451-btn-label-xl;
}

.elm-app451-navigation {
  @include elm-app451-navigation;
}

.elm-app451-status-label {
  @include elm-app451-body-2;
  font-size: 0.75rem;
  margin: 0 0 0.5rem;
  text-transform: uppercase;
}

.elm-app451-status {
  @include elm-app451-body-2;
  color: #ffffff;
}

.elm-app451-semi-bold {
  font-weight: 500 !important;
}

.elm-app451-bold {
  font-weight: bold !important;
}

.elm-app451-separator {
  @include elm-app451-separator;
}
