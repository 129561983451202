// --- Foreground Colors --- //

@use '@angular/material' as mat;
@import 'libs/styles/variables';
@import 'foreground-colors-slim';

// Foreground Primary

.elm-fg-primary {
  color: mat.m2-get-color-from-palette($element451-app-primary);
}

.elm-fill-primary {
  fill: mat.m2-get-color-from-palette($element451-app-primary) !important;
}

.elm-fg-primary-50 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 50);
}

.elm-fg-primary-100 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 100);
}

.elm-fg-primary-200 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 200);
}

.elm-fg-primary-300 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 300);
}

.elm-fg-primary-400 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 400);
}

.elm-fg-primary-500 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 500);
}

.elm-fg-primary-600 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 600);
}

.elm-fg-primary-700 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 700);
}

.elm-fg-primary-800 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 800);
}

.elm-fg-primary-900 {
  color: mat.m2-get-color-from-palette($element451-app-primary, 900);
}

.elm-fg-primary-A100 {
  color: mat.m2-get-color-from-palette($element451-app-primary, A100);
}

.elm-fg-primary-A200 {
  color: mat.m2-get-color-from-palette($element451-app-primary, A200);
}

.elm-fg-primary-A400 {
  color: mat.m2-get-color-from-palette($element451-app-primary, A400);
}

.elm-fg-primary-A700 {
  color: mat.m2-get-color-from-palette($element451-app-primary, A700);
}

// Foreground Accent

.elm-fg-accent {
  color: mat.m2-get-color-from-palette($element451-app-accent);
}

.elm-fg-accent-50 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 50);
}

.elm-fg-accent-100 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 100);
}

.elm-fg-accent-200 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 200);
}

.elm-fg-accent-300 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 300);
}

.elm-fg-accent-400 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 400);
}

.elm-fg-accent-500 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 500);
}

.elm-fg-accent-600 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 600);
}

.elm-fg-accent-700 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 700);
}

.elm-fg-accent-800 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 800);
}

.elm-fg-accent-900 {
  color: mat.m2-get-color-from-palette($element451-app-accent, 900);
}

.elm-fg-accent-A100 {
  color: mat.m2-get-color-from-palette($element451-app-accent, A100);
}

.elm-fg-accent-A200 {
  color: mat.m2-get-color-from-palette($element451-app-accent, A200);
}

.elm-fg-accent-A400 {
  color: mat.m2-get-color-from-palette($element451-app-accent, A400);
}

.elm-fg-accent-A700 {
  color: mat.m2-get-color-from-palette($element451-app-accent, A700);
}

// Foreground Warn

.elm-fg-warn {
  color: mat.m2-get-color-from-palette($element451-app-warn);
}

.elm-fg-warn-50 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 50);
}

.elm-fg-warn-100 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 100);
}

.elm-fg-warn-200 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 200);
}

.elm-fg-warn-300 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 300);
}

.elm-fg-warn-400 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 400);
}

.elm-fg-warn-500 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 500);
}

.elm-fg-warn-600 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 600);
}

.elm-fg-warn-700 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 700);
}

.elm-fg-warn-800 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 800);
}

.elm-fg-warn-900 {
  color: mat.m2-get-color-from-palette($element451-app-warn, 900);
}

.elm-fg-warn-A100 {
  color: mat.m2-get-color-from-palette($element451-app-warn, A100);
}

.elm-fg-warn-A200 {
  color: mat.m2-get-color-from-palette($element451-app-warn, A200);
}

.elm-fg-warn-A400 {
  color: mat.m2-get-color-from-palette($element451-app-warn, A400);
}

.elm-fg-warn-A700 {
  color: mat.m2-get-color-from-palette($element451-app-warn, A700);
}
