// --- Main --- //

@import 'libs/styles/variables';

html {
  background-color: white;
  color: $elm-fg-7;
  font-family: $elm-font-family;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.5;
  min-width: 320px;

  /* Fix for webkit rendering */
  -webkit-font-smoothing: antialiased;
}

/*
 * Mainly used to fix froala inline toolbar issue
 * when it gets positioned relative to <html> tag
 * and .cdk-global-scrollblock adds styling needed for `block scroll` strategy that breaks froala position
 */
body {
  position: relative;
  min-height: 100vh;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: $elm-color-brand;
  color: white;
  text-shadow: none;
}

::selection {
  background: $elm-color-brand;
  color: white;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: $elm-rule-height;
  border: 0;
  border-top: 1px solid $elm-color-rule;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}
