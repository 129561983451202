/**
 * Use this helper class on mat-select to make expandable text
 * when you need content to wrap into multiple lines
 * and not to be truncated
 */
.mat-mdc-select.elm-mat-select-text-expanded {
  .mat-mdc-select-trigger {
    height: auto !important;
  }

  .mat-mdc-select-value {
    overflow: visible;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .mat-mdc-select-value-text {
    white-space: pre-wrap;
  }
}
