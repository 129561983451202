// --- Text overflow --- //

// Requires inline-block or block for proper styling

@mixin text-overflow($display: inline-block) {
  display: $display;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // line-height: 120%;
}
