@import 'libs/styles/variables';

$elm-notification-bar-spacing: 20px;

.mat-mdc-snack-bar-container {
  &.elm-notifications-bar {
    margin-top: $elm-size-main-nav-icon-container +
      $elm-notification-bar-spacing;
    margin-right: $elm-notification-bar-spacing;
  }
}
