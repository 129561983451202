@import 'variables';
@import 'button-base';
@import 'typography';

.elm-app-block-action-button {
  @extend %elm-app451-button-base;
  @include elm-app451-btn-label-s;
  border: 2px solid #e4e6e8;
  border-radius: 5px;
  color: #757575;
  line-height: 100%;
  padding: 8px 16px !important;

  &.elm-button-confirm-active {
    border-color: $elm-app451-color-red;
    color: $elm-app451-color-red;
  }
}

.elm-app-block-form-button {
  @extend %elm-app451-button-base;
  @include elm-app451-btn-label-l;
  border-radius: 5px;
  line-height: 100%;
  padding: 16px 48px;
}

.elm-app-wide-button {
  @extend %elm-app451-button-base;
  @include elm-app451-btn-label-l;
  padding: 30px;
  color: white;
  fill: white;

  elm-icon {
    margin-left: 5px;
  }

  &.elm-submit {
    background-color: #4eaf3e;
  }
}
