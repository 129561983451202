// --- Paddings --- //

body .elm-no-padding {
  padding: 0 !important;
}

body .elm-no-padding-top {
  padding-top: 0;
}

body .elm-no-padding-right {
  padding-right: 0;
}

body .elm-no-padding-bottom {
  padding-bottom: 0;
}

body .elm-no-padding-left {
  padding-left: 0;
}
