// --- Typography --- //

@import '../variables';

@mixin elm-app451-display-1 {
  font-family: $elm-app451-font-family-work-sans; // will be overwritten only inside of billboard components
  font-weight: 300; // will be overwritten only inside of billboard components
  margin: 0 0 0.5rem;
  color: white;

  @media (min-width: $elm-app451-layout-breakpoint-xs) {
    font-size: 1.875rem;
    line-height: 116%;
  }

  @media (min-width: $elm-app451-layout-breakpoint-sm) {
    font-size: 2.25rem;
  }

  @media (min-width: $elm-app451-layout-breakpoint-lg) {
    font-size: 4rem;
    line-height: 118%;
  }
}

@mixin elm-app451-display-2 {
  font-family: $elm-app451-font-family-work-sans; // will be overwritten only inside of billboard components
  font-weight: 300; // will be overwritten only inside of billboard components
  margin: 0 0 0.5rem;
  color: white;

  @media (min-width: $elm-app451-layout-breakpoint-xs) {
    font-size: 1.875rem;
    line-height: 116%;
  }

  @media (min-width: $elm-app451-layout-breakpoint-sm) {
    font-size: 2.25rem;
  }

  @media (min-width: $elm-app451-layout-breakpoint-lg) {
    font-size: 3rem;
    line-height: 118%;
  }
}

@mixin elm-app451-display-3 {
  font-family: $elm-app451-font-family-work-sans;
  font-weight: 300;
  line-height: 116%;
  margin: 0 0 0.5rem;
  color: $elm-app451-color-dark-gray;

  @media (min-width: $elm-app451-layout-breakpoint-xs) {
    font-size: 1.875rem;
  }

  @media (min-width: $elm-app451-layout-breakpoint-sm) {
    font-size: 2.25rem;
  }
}

@mixin elm-app451-display-4 {
  font-family: $elm-app451-font-family-source-sans-pro;
  font-weight: 400;
  margin: 0 0 1rem;
  color: $elm-app451-color-dark-gray;

  @media (min-width: $elm-app451-layout-breakpoint-xs) {
    font-size: 1.25rem;
    line-height: 150%;
  }

  @media (min-width: $elm-app451-layout-breakpoint-lg) {
    font-size: 1.5rem;
    line-height: 129%;
  }
}

@mixin elm-app451-display-5 {
  font-family: $elm-app451-font-family-source-sans-pro;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 1rem;
  color: white;

  @media (min-width: $elm-app451-layout-breakpoint-xs) {
    font-size: 1rem;
  }

  @media (min-width: $elm-app451-layout-breakpoint-md) {
    font-size: 1.25rem;
  }
}

@mixin elm-app451-display-tray {
  font-family: $elm-app451-font-family-source-sans-pro;
  font-weight: 300;
  line-height: 116%;
  margin: 0 0 0.5rem;
  color: $elm-app451-color-dark-gray;

  @media (min-width: $elm-app451-layout-breakpoint-xs) {
    font-size: 1.875rem;
  }

  @media (min-width: $elm-app451-layout-breakpoint-sm) {
    font-size: 2.25rem;
  }
}

@mixin elm-app451-body-1 {
  font-family: $elm-app451-font-family-source-sans-pro;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  margin: 0 0 1rem;
  color: $elm-app451-color-normal-gray;
}

@mixin elm-app451-body-2 {
  font-family: $elm-app451-font-family-source-sans-pro;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  margin: 0 0 1rem;
  color: $elm-app451-color-normal-gray;
}

@mixin elm-app451-btn {
  border-radius: 5px;
  font-family: $elm-app451-font-family-source-sans-pro;
  line-height: 100%;
  padding: 12px 16px;
}

@mixin elm-app451-btn-wide {
  @include elm-app451-btn;

  width: 100%;
  padding: 16px;
}

@mixin elm-app451-btn-wide-xl {
  @include elm-app451-btn;

  width: 100%;
  padding: 24px;
}

@mixin elm-app451-btn-round {
  @include elm-app451-btn;

  border-radius: 18px;
}

@mixin elm-app451-btn-dotted {
  @include elm-app451-btn-wide;
  @include elm-app451-btn-label-l;

  border: 2px dashed $elm-app451-color-light-gray;
  border-radius: 5px;
  background-color: transparent;
  color: $elm-app451-color-light-gray;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  padding: 16px 48px;
  margin-right: 16px;

  elm-icon {
    top: 0;
    margin-right: 8px;
  }
}

@mixin elm-app451-label {
  font-family: $elm-app451-font-family-source-sans-pro;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 125%;
  margin: 0 0 0.5rem;
  color: $elm-app451-color-light-gray;
}

@mixin elm-app451-btn-label-s {
  font-family: $elm-app451-font-family-work-sans;
  font-weight: 600;
  font-size: 0.75rem;

  // line-height: 125%;
  margin: 0;
  color: white;
  text-transform: uppercase;
}

@mixin elm-app451-btn-label-l {
  font-family: $elm-app451-font-family-work-sans;
  font-weight: 600;
  font-size: 0.875rem;

  // line-height: 150%;
  margin: 0;
  color: white;
  text-transform: uppercase;
}

@mixin elm-app451-btn-label-xl {
  @include elm-app451-btn-label-l;

  font-size: 1rem;
  letter-spacing: 5px;
}

@mixin elm-app451-navigation {
  font-family: $elm-app451-font-family-work-sans;
  font-weight: 500;
  font-size: 0.875rem;
  margin: 0;
  color: $elm-app451-color-normal-gray;

  @media (min-width: $elm-app451-layout-breakpoint-xs) {
    line-height: 342%;
  }

  @media (min-width: $elm-app451-layout-breakpoint-md) {
    line-height: 228%;
  }
}

@mixin elm-app451-separator {
  border-top: 1px solid $elm-app451-color-lightest-gray;
  height: 0px;
}
