@import 'variables';
@import 'mixins/typography';

// global dialog styling
.elm-dialog-pane {
  .mat-mdc-dialog-container {
    border-radius: 5px !important;

    elm-dialog {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      max-height: 100vh;
      max-height: -webkit-fill-available;

      .mat-mdc-dialog-title {
        .elm-dialog-title {
          @include elm-app451-display-4;
          font-weight: 600;
          margin: 0 0 8px 0;
        }

        .elm-dialog-description {
          @include elm-app451-body-1;
          margin: 0;
        }

        &.elm-no-border-bottom {
          padding-bottom: 0 !important;
        }
      }

      .mat-mdc-dialog-title,
      .mat-mdc-dialog-actions {
        flex: 0 0 auto;
      }

      .mat-mdc-dialog-content {
        flex: 1 1 auto;
      }

      .mat-mdc-dialog-actions {
        .elm-dialog-actions {
          display: block;
          float: unset !important;
          padding: 0 $elm-app451-container-padding-md;
        }

        &.elm-no-border-top .elm-dialog-actions {
          padding-top: 0;
        }
      }

      &.elm-dialog-no-content {
        .mat-mdc-dialog-title.elm-no-border-bottom {
          padding-bottom: $elm-app451-container-padding-sm !important;
        }
        .mat-mdc-dialog-actions.elm-no-border-top .elm-dialog-actions {
          padding-top: $elm-app451-container-padding-sm !important;
        }
      }
    }
  }
}
