@import 'libs/page451-lib/src/lib/core/styles/button-base';

.elm-pg-dialog-pane {
  margin: 0 auto !important;

  .mat-mdc-dialog-container {
    overflow: scroll;
    max-height: 100vh;

    @media (max-width: $layout-breakpoint-md) {
      max-height: 90vh;
    }
  }

  @supports (max-height: 100dvh) {
    .mat-mdc-dialog-container {
      max-height: 100dvh;
    }
  }

  .mat-mdc-dialog-surface {
    padding: 40px;
  }

  .mat-mdc-dialog-actions {
    padding: 12px 0 24px;
  }

  .elm-dialog-close-wrapper {
    position: relative;
  }

  .elm-dialog-close {
    @extend %elm-button-base;

    background-color: transparent;
    color: black;
    position: absolute;
    top: -16px;
    right: -16px;
    box-shadow: none !important;

    svg {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}

.elm-pg-media-dialog-pane {
  max-width: 100% !important;

  .mat-mdc-dialog-container {
    overflow: hidden;
    max-width: 100%;
  }

  .mat-mdc-dialog-surface {
    padding: 40px 0;

    @media (min-width: $layout-breakpoint-md) {
      padding: 40px;
    }
  }

  .elm-dialog-close {
    color: white;
    top: -40px;
    right: 16px;

    @media (min-width: $layout-breakpoint-md) {
      right: -40px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
