// --- Margins --- //

body .elm-no-margin {
  margin: 0;
}

body .elm-no-margin-top {
  margin-top: 0;
}

body .elm-no-margin-right {
  margin-right: 0;
}

body .elm-no-margin-bottom {
  margin-bottom: 0;
}

body .elm-no-margin-left {
  margin-left: 0;
}

body .elm-margin-bottom-form-element {
  margin-bottom: $elm-margin-bottom-form-element;
}

body .elm-margin-bottom-content-title-subtitle {
  margin-bottom: $elm-margin-bottom-content-title-subtitle;
}

body .elm-margin-bottom-content-section {
  margin-bottom: $elm-margin-bottom-content-section;
}
